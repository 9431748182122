.btn {
    border: none !important;
    margin: 0 1%;
}

.btn-backloggd {
    background-color: rgba(0, 0, 0, .8) !important;
    margin: 1%;
}

.btn-github {
    background-color: rgba(0, 0, 0, .8) !important;
    border: none !important;
}

.btn-letterboxd {
    background: linear-gradient(to right, #ff8000 0%, #00e054 50%, #40bcf4 100%);
}

.contact-container {
    background-image: url('../images/colorado_small.jpg');
    min-height: 80vh;
}

.email-text a {
    color: white;
    text-decoration: underline;
}

.email-text {
    color: white;
    text-align: center;
}

.img-backloggd {
    height: 16px;
    width: 16px;
}
