.echoes-container {
    background-color: black;
}

#echoes .intro {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
}

#echoes .jumbotron {
    background-color: dimgray !important;
    box-shadow: 0 8px 12px 0 black;
    margin: 2% 10%;
    padding: 5%;
    width: 100%;
}

#echoes .divider {
    background-color: white;
}

.blog-archive {
    margin: 2vh;
    text-align: center;
}

.blog-archive .title {
    color: white;
    font-size: 5vh;
}

.blog-archive .button {
    background: none;
    border: none;
    border-bottom: 3px dotted gray;
    color: white;
    font-size: 3vh;
    margin: 3px;
}

.blog-archive .button:hover {
    border-bottom: 3px dotted darkviolet;
}

.post .button {
    background-color: dimgray !important;
    border-radius: .25rem .25rem .25rem .25rem !important;
    font-family: 'Courier New', Courier, monospace;
    margin: 0px !important;
}

.post .btn:hover {
    background-color: rgb(179, 175, 175) !important;
}

.post-container {
    background-color: black;
    min-height: 20vh;
}

.post .jumbotron {
    background-color: rgb(135, 135, 135) !important;
    box-shadow: 0 8px 12px 0 black;
    margin: 3% 10%;
    padding: 5%;
    width: 100%;
}

.post .title {
    color: white;
    font-size: 48px;
}

.post .date {
    color: gainsboro;
}

.post .image {
    border: 1px dotted black;
    max-height: 800px;
    padding: 2vh;
}

.post .text {
    color: white;
}

.post .text a {
    color: #27405a;
}

.post .caption {
    color: white;
    font-style: italic;
    text-align: center;
}

.post .return {
    align-items: center;
    background-color: dimgray !important;
    border-radius: .25rem .25rem .25rem .25rem !important;
    font-family: 'Courier New', Courier, monospace;
    margin: 0px !important;
    width: 100%;
}

.post .return:hover {
    background-color: rgb(179, 175, 175) !important;
}
