.home-btn-whats-new {
    border: 2px double rgb(221, 219, 200) !important;
    box-shadow: 0 8px 12px 0 black;
    background-color: rgb(58, 58, 58, 0.85) !important;
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 150% !important;
    margin: 5% 0% 4vh;
    padding: 2% !important;
    text-align: center;
    width: 100%;
}

.home-btn-whats-new:hover {
    background-color: rgba(184, 184, 184, 0.3) !important;
}

.home-col-bg {
    border-style: double;
    border-radius: 5px;
    border-color: rgb(221, 219, 200);
    padding-top: 1vh;
    padding-bottom: 2vh;
}

.home-col-colors {
    border-style: double;
    border-radius: 5px;
    border-color: rgb(221, 219, 200);
    padding-top: 1vh;
    padding-bottom: 2vh;
}

.home-color-btn {
    border: 3px solid #dee2e6 !important;
    margin: auto;
    padding: 2vh !important;
}

.home-color-btn:active, .home-color-btn:focus {
    border-color: gold !important;
    box-shadow: none !important;
}

.home-container {
    background-image: url('../images/tokyo_street_river_small.jpg');
    min-height: 100vh;
}

.home-divider {
    background-color: white;
}

.home-header {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 72px;
}

.home-photo {
    padding-top: 2vh;
}

.home-progress-bar {
    background-color: rgb(156, 186, 206) !important;
    border: 3px solid #dee2e6;
    margin-bottom: 2vh;
    width: 100%;
}

.home-row-flat {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.home-row-skills {
    align-items: center;
    margin-top: 2vh;
    margin-left: 0 !important;
}

.home-skills-bubble {
    border: 1px solid #dee2e6;
    border-radius: 100%;
}

.home-skills-scrollbar {
    height: 30vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: rgba(88, 102, 224) rgb(156, 186, 206);
    scrollbar-width: thin;
}

.home-small-header {
    color: white !important;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 2rem;
    padding-left: 1vw;
}

.home-subtitle {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 40px;
    padding-left: 1vw;
}

.home-tab {
    color: white !important;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 24px;
    padding-left: 1vw;
}

.home-tab-inner {
    color: white !important;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 20px;
    padding-left: 1vw;
}

.home-text {
    color: white;
}

.home-window-color-text {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 2rem;
    text-align: center;
}

.nav-link.active {
    background-color: rgba(88, 102, 224, 0.55) !important;
}

@media (max-width: 850px){
    .home-header {
        font-size: 32px;
    }

    .home-subtitle {
        font-size: 16px;
    }
}
