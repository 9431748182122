.nav-button {
    background-color: rgb(58, 58, 58, 0.5) !important;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    width: 100%;
}

.nav-button:hover {
    background-color: rgba(184, 184, 184, 0.3) !important;
    border-color:rgb(0, 0, 0) !important;
}

.nav-container {
    background-attachment: fixed;
    background-color: rgb(58, 58, 58) !important;
    background-size: cover;
    box-shadow: 0 2px 5px 0 black;
    display: flex;
}
