.general-container {
    align-items: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
}

.general-header {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 76px;
    text-align: center;
}

.general-image-col {
    max-width: 40% !important;
}

.general-image {
    display: block;
    margin: 0 auto;
    max-height: 500px;
}

.general-jumbotron {
    background-color: rgba(15, 15, 15, 0.60) !important;
    box-shadow: 0 8px 12px 0 black;
    margin: 5% 10%;
    padding: 5%;
    width: 100%;
}

.general-links-row {
    margin-top: 2vh;
    text-align: center;
}

@media (max-width: 860px) {
    .general-header {
        font-size: 40px;
    }
}
