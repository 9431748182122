.novels-button {
    background-color: rgb(58, 58, 58, 0.5) !important;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 150% !important;
    margin-top: 2vh;
    text-align: center;
    width: 100%;
}

.novels-button:hover {
    background-color: rgba(184, 184, 184, 0.3) !important;
}

.novels-container {
    background-image: url('../images/portugal_garden_small.jpg');
    min-height: 140vh;
}

.novels-header {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 76px;
    text-align: center;
}

.novels-subtitle {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 40px;
    text-align: left;
}

.novels-desc {
    color: white;
    padding-right: 2vh;
}

.novels-links {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    text-align: center;
}

a.novels-links, a.novels-links:link, a.novels-links:visited {
    color: hotpink;
    text-decoration: none;
}

a.novels-links:hover {
    color:lightgoldenrodyellow;
    text-decoration: none;
}

.goodreads-button {
    background-color: rgb(58, 58, 58, 0) !important;
    background-color: none !important;
    margin-top: 2vh;
}

.goodreads-height-buffer {
    margin-top: 2rem;
    text-align: center;
}

@media (max-width: 860px) {
    .novels-header {
        font-size: 48px;
    }

    .novels-subtitle {
        font-size: 32px;
    }
}
